<template>

  <button
    @click="onClickBackToTop"
    class="theTopButton clickable"
    alt=""
  ></button>
</template>

<script>
export default {
  methods: {
    onClickBackToTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss">
.theTopButton {
  width: 7rem;
  height: 7rem;
  position: fixed;
  right: 5px;
  top: 80vh;
  background-image: url('~@/assets/image/common/Top.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
  border: none;
  background-color: transparent;
}

@media (max-width: 768px) {
  .theTopButton {
    width: 5rem;
    height: 5rem;
  }
}
</style>
